.wrap{
	margin-top:85px;
	@media only screen and (min-width : 768px){
		margin-top:65px;
	}
}

.sidebar{
	margin-top:42px;
	margin-bottom:15px;
}
.archive main.main, .single-post main.main{
	margin-bottom:15px;
}
.single-post main.main{
	margin-top:42px;
}
.page-header {
    padding-bottom: 9.5px;
    margin: 0px 0 30px;
    border-bottom: 1px solid #ddd;
}

.post {
	margin-top:15px;
	margin-bottom:15px;
	padding-bottom:15px;
	border-bottom:1px solid $iron;
}

.single-post .post{
	margin-top:0px;
	.entry-title{
		margin-top:0px;
	}
}

.main{
	.page-content{
		//margin-bottom:25px;
	}
}

// Home Page
.fp-alert{
	background-color: $hfm-grey;
	padding-top:15px;
	padding-bottom:0px;
}
.fp-lead-blocks{
	padding-top:30px;
	.fp-block{
		background:$hfm-grey;
		padding:15px 0px;
		margin:0px;
		h1{
			font-family:'Oswald', sans-serif;
			font-weight:bold;
			text-transform: lowercase;
			color: $hfm-red;
			font-size:1.6em;
			margin-top:0px;
			@media only screen and (min-width: 667px) {
				font-size:2.6em;
			}
			@media only screen and (min-width: 768px) {
				font-size:1.6em;
			}
			@media only screen and (min-width: 1024px) {
				font-size:2.2em;
			}
			@media only screen and (min-width: 1200px) {
				font-size:2.6em;
			}
		}
		h2{
			font-size: 1.4em;
		}

		img{
			margin:15px auto;
			display:block;
			border-radius:50%;
			padding-bottom:0px;
		}
	}
}


.fp-events{
	padding-top:30px;
	padding-bottom:30px;
	h1{
		font-family: 'Oleo Script', cursive;
		margin-top:0px;
	}
	ul{
		list-style: none;
		margin:15px 0px;
		padding:0px;
		@media only screen and (min-width: 1024px) {
			-moz-column-count: 2;
			-moz-column-gap: 2.5em;
			-webkit-column-count: 2;
			-webkit-column-gap: 2.5em;
			column-count: 2;
			column-gap: 2.5em;
		}
	}
	li{
		line-height:1.8em;
	}
	.date{
		font-weight:bold;
	}
}

.fp-recipe{
	padding-top:30px;
	padding-bottom: 15px;
	margin-bottom:15px;
	background-color: $hfm-grey;
	.fp-recipe-content{
		margin-bottom:15px;
		h1{
			border-bottom:3px solid $hfm-yellow;
			line-height:1.6em;
			margin-bottom:30px;
			margin-top:0px;
		}
	}
	.fp-recipe-image{
		margin-bottom:15px;
	}
}

.fp-testimonials{
	margin-top:-15px;
	padding-top:60px;
	padding-bottom:60px;
	background: transparent url(../images/red-barn-tiled.png) center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	h1{
		font-size:3.4em;
		margin-top:0px;
		margin-bottom:30px;
	}
}

.fp-testimonial{
	margin-bottom:15px;
	.quote{
		font-family:'Oswald';
		font-size:6em;
		margin:0px 0 0 15px;
		color:$hfm-green;
	}
	.fp-test-content{
		background-color: $hfm-grey;
		padding:30px 30px 30px 60px;
		height:100%;
	}
	.fp-test-attribution{}
}

.fp-location{
	padding-top:300px;
	padding-bottom:15px;
	background: transparent url('../images/hfm-map-bg-x1.jpg') center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover; 
	.fp-contacts{
		padding-top:15px;
		padding-bottom:0px;
		background: $hfm-grey;
		
	}
	.fp-contact{
		margin-bottom:15px;
		font-size:0.8em;
		@media only screen and (min-width: 992px) {
			font-size:0.6em;
		}
		@media only screen and (min-width: 1200px) {
			font-size: 0.8em;
		}
		.fa{
			color:$hfm-red;
			@media only screen and (max-width: 666px) {
				font-size:1.2em;
			}
		}
		a{
			color:$hfm-red;
			font-weight:bold;
		}
		@media only screen and (min-width: 992px) {
			border-right:1px solid $black;
		}
	}
	#fa-content03{
		border-right:0px;
	}
}

// Events & Entertainment Page
.event_list{
	padding-top:25px;
	padding-bottom:25px;
	background: transparent url(../images/red-barn-tiled.png) center center;
	h1{
		font-size:3em;
	}
}
.event_item{
	background:$hfm-grey;
	line-height:1.6em;
	padding:15px;
	margin-bottom:15px;
	img{
		//vertical-align: text-top;
		float:left;
		margin-right:15px;
	}
	.event_content{
		margin-left:115px;
		margin-top:0px;
		@media only screen and (min-width: 992px) {
			//margin-top:25px;
		}
	}
	.event_date,.event_time{
		display:block;
		font-size:1.2em;
		font-weight:bold;
		@media only screen and (min-width: 768px) {
			font-size:1.4em;
		}
		@media only screen and (min-width: 992px) {
			padding-bottom:15px;
		}
	}
	.event_details{
		overflow:hidden;
		font-size: 0.8em;
		line-height:1.0em;
		@media only screen and (min-width: 768px) {
			font-size:1.2em;
		}
	}
}

// Recipes Page
 
.recipes{
	.featured_recipe{
		background:$hfm-grey;
		padding-top:30px;
		padding-bottom:30px;
		h1{
			border-bottom: 3px solid #F9C622;
			line-height: 1.6em;
			margin-bottom: 30px;
			margin-top: 0;
		}
	}

	.recipes_list{
		padding-top:30px;
		padding-bottom:15px;
		h2{
			margin-top:0px;
		}
		.recipe_item{
			border-bottom:2px solid $hfm-grey;
			margin-bottom:15px;
		}
	}
}

// Vendor Pages

.vendors{

	.vendor-list{
		padding-top:25px;
		padding-bottom:25px;
		background: transparent url(../images/red-barn-tiled.png) center center;
		h1{
			font-size:3em;
		}
	}
	.vendor-wrapper{
		margin-bottom:25px;
	}
	.vendor{
		height:100%;
		background-color:$hfm-grey;
		overflow:hidden;
		position:relative;
		padding:10px;
		h2.entry-title{
			line-height: 1.2em;
			font-size: 1.4em;
			padding:0px 0px 10px;
			margin:0px;
			@media only screen and (min-width: 768px) {
				font-size: 2em;
			}
		}
		.entry-summary{
			padding:0px 0px 10px;
			p{
				line-height: 1.0em;
					font-size: 1.0em;
				@media only screen and (min-width: 768px) {
					line-height: 1.4em;
					font-size: 1.4em;
				}
			}
		}
		img{
			display:block;
			margin:0px auto;
		}
	}
	.ribbon-wrapper{
		width: 120px;
		height: 120px;
		overflow: hidden;
		position: absolute;
		top: 0px;
		right: 0px;
	}
	.ribbon{
		font-family: 'Oswald', Sans-Serif;
		font-size:1.4em;
		color: $white;
		background-color: $hfm-red;
		text-align: center;
		box-shadow: 0px 0px 15px #000000;
		-webkit-transform: rotate(45deg);
		-moz-transform:    rotate(45deg);
		-ms-transform:     rotate(45deg);
		-o-transform:      rotate(45deg);
		position: relative;
		padding: 5px 0;
		right: 15px;
		top: 15px;
		width: 200px;
	}
}
.single-vendor{
	.vendor{
		img.wp-post-image{
			display: block;
			float:left;
			margin: 0 15px 15px 0;
		}
		&:after{
			content:'';
			height:5px;
			display:block;
			color:#00ffff;
			width:100%;
			clear:both;
		}
	}
}

.post-share{
	border:1px solid $hfm-grey;
	padding:15px;
}

.post-nav{
	padding-top:15px;
	padding-bottom:15px;
}

@media only screen and (min-width: 768px) {

}
@media only screen and (min-width: 992px) {

}
@media only screen and (min-width: 1200px) {

}