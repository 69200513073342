// .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
//     font-family: "Bree Serif",Bevan","Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
//     font-weight: 300;
//     line-height: 1.1;
//     color: inherit;
// }

body{
	font-family: 'PT Sans', 'Helvetica Neue', sans-serif;
	font-size:2em;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: 'Oswald', 'Helvetica Neue', sans-serif;
	//font-weight:700;
}
.script{
	font-family: 'Oleo Script', cursive;
}
.mega-script{
	font-family: 'Oleo Script', cursive;
	color:$white;
	text-shadow:5px 5px 10px $black;
	margin-top:0px;
	font-size:3em;
}
.btn{
	text-transform: uppercase;
	font-family: 'Oswald', 'Helvetica Neue', sans-serif;
}
.panel{
	border-width: 3px;
}
.main{
	padding-left:0px;
	padding-right:0px;
}
a {
	color: $hfm-green;
}
a:focus, a:hover {
    color: $hfm-dk-green;
    text-decoration: underline;
}
a:active {

}
a:active, a:hover {
    outline: 0;
}
a:focus {
    outline: none;
    outline: 0px;
    outline-offset: 0px;
}
img{
	max-width:100%;
	height:auto;
}
.txt-xs{
	font-size:0.6em;
}
.txt-sm{
	font-size: 0.8em;
}
.txt-md{
	font-size: 1.0em;
	@media only screen and (min-width: 768px) {
		font-size: 1.4em;
	}
}
.txt-lg{
	font-size: 1.2em;
	@media only screen and (min-width: 768px) {
		font-size: 1.6em;
	}
}
.txt-xl{
	font-size: 1.4em;
	@media only screen and (min-width: 768px) {
		font-size: 2.0em;
	}
}

.p-title{
	margin-bottom:30px;
}
.num-list ol {
    margin-left: 20px;
    padding-right: 0;
    list-style-type: none;
}

.num-list ol li {
    counter-increment: step-counter;
}
.num-list ol li::before {
	// content: counter(step-counter);
	// margin-left: -60px;
	// font-size: 24px;
	// background-color: #eadecb;
	// color: #b2553d;
	// font-weight: 700;
	// padding: 0px;
	// display: block;
	// border-radius: 22px;
	// height: 44px;
	// width: 44px;
	// text-align: center;
	// float: left;
	// clear: both;
	// border: 4px solid #b2553d;

	content: counter(step-counter);
	margin-left: -60px;
	font-size: 24px;
	background-color: #b2553d;
	color: #ffffff;
	font-weight: 700;
	padding: 0;
	display: block;
	border-radius: 22px;
	height: 44px;
	width: 44px;
	text-align: center;
	float: left;
	clear: both;
	border: 4px solid #000000;
}

.lead{
	color:#666;
	line-height:1.6em;
	font-weight:400;
}

.bg-snow{
	background-color: $snow;
}
.bg-silver{
	background-color: $silver;
}
.bg-iron{
	background-color: $iron;
}
.bg-jumbo{
	background-color: $jumbo;
}
.bg-steel{
	background-color: $steel;
}
.bg-oil{
	background-color: $oil;
}
.bg-black{
	background-color: $black;
}

.bg-blue{
	background-color: $blue;
}

.bg-ltblue{
	background-color: $ltblue;	
}

.bg-drkblue{
	background-color: $drkblue;
}

.bg-yellow{
	background-color: $yellow;
}
.bg-hfm-grey{
	background-color: $hfm-grey;
}
.bg-hfm-redbarn{
	background: transparent url(../images/red-barn-tiled.png) center center;
}
.text-white{
	color: $white!important;
}
.text-snow{
	color: $snow!important;
}
.text-silver{
	color: $silver!important;
}
.text-iron{
	color: $iron!important;
}
.text-jumbo{
	color: $jumbo!important;
}
.text-steel{
	color: $steel!important;
}
.text-oil{
	color: $oil!important;
}
.text-shadow{
	text-shadow: 0px 1px 5px #000000;
}
.events-col{
	margin-bottom:25px;
}
#google_translate_element {
    margin-top: 4px;
    margin-right: 10px;
}

.intrinsic-container {
  position: relative;
  height: 0;
  overflow: hidden;
}
 
/* 16x9 Aspect Ratio */
.intrinsic-container-16x9 {
  padding-bottom: 56.25%;
}
 
/* 4x3 Aspect Ratio */
.intrinsic-container-4x3 {
  padding-bottom: 75%;
}
.intrinsic-container-8x11 {
  padding-bottom: 100%;
}
 
.intrinsic-container iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

