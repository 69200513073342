.navbar{
	margin-bottom: -65px;
	background:rgba(255,255,255,0.8);
	.navbar-toggle{
		padding:22px 10px;
		.icon-bar{
			background-color:#244330;
		}
	}
	&.affix{
		position:relative;
	}
	@media only screen and (min-width: 768px) {
		.navbar-collapse {
		  text-align:center;
		}
		.navbar-nav {
			display:inline-block;
			float:none;
		}
		&.affix{
			position:fixed;
			top:0px;
			width:100%;
			z-index: 99;
		}
	}
	#menu-primary-nav{
		font-family: 'Oswald';
		font-size: 1.2em;
		li{
			a{
				text-transform: uppercase;
				color:#244330;
				padding:20px 16px;
				-webkit-transition: background-color 500ms linear;
				-ms-transition: background-color 500ms linear;
				transition: background-color 500ms linear;
				&:hover,&:focus{
					background-color:#D3F489;
					color:#244330!important;
					-webkit-transition: background-color 500ms linear;
					-ms-transition: background-color 500ms linear;
					transition: background-color 500ms linear;
				}
			}
			&.active{
				a{
					color:#7FB700;
				}
			}
		}
	}
}
