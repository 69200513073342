/* Masthead */

.site-masthead{
  background: white url("../images/hfm-header-bg-x1.jpg") center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding:10px 0;
  text-align:center;
  @media only screen and (min-width: 768px) {
    padding:42px 0;
    text-align:left;
  }
  @media only screen and (min-width: 992px) {
    padding:16px 0;
  }
  @media only screen and (min-width: 1200px) {
    padding:10px 0;
  }
}
.site-logo{}
.site-description{
  h2{
    text-align:center;
    font-family: 'Oleo Script', cursive;
    font-weight:500;
    font-size:1.2em;
    @media only screen and (min-width: 361px) {
      font-size: 1.4em;
    }
    @media only screen and (min-width: 768px) {
      font-size: 2em;
    }
    @media only screen and (min-width: 992px) {
      font-size: 2.6em;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 3.2em;
    }        
    text-shadow: 5px 5px 10px #000000;
    color:white;
  }
}

/* Carousel/Slider styles */

.carousel-fade .carousel-inner .item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.carousel-fade .carousel-control {
  z-index: 2;
}

/* multi image carousel */

.carousel-multi-image{
  .carousel-inner{
    > .item{
      
      transition: 500ms ease-in-out left;

    }
    .active{
      &.left{
        left:-25%;
      }
      &.right{
        left:25%;
      }
    }
    .next{
      left: 25%;
    }
    .prev{
      left: -25%;
    }
    @media all and (transform-3d), (-webkit-transform-3d) {
      > .item{
        // use your favourite prefixer here
        transition: 500ms ease-in-out all;
        backface-visibility: visible;
        transform: none!important;
      }
    }
  }
  .carouse-control{
    &.left, &.right{
      background-image: none;
    }
  }
}

/*
 *  Carousel Default Theme
 */
.carousel-theme-default .carousel-caption {
  left: 0%;
  right: 0%;
  padding: 5px 15px 25px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  margin: 0px;
  color: #FFF;
  text-align: center;
  z-index: 10;
}
.carousel-theme-default .carousel-caption p {
  margin: 0px;
}
.carousel-theme-default .carousel-indicators {
  margin-bottom: 0px;
  bottom: 0px;
}
.carousel-theme-default .carousel-indicators li {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0px;
  text-indent: -999px;
  border: 1px solid #FFF;
  border-radius: 10px;
  cursor: pointer;
  background-color: transparent;
}
.carousel-theme-default .carousel-indicators .active {
  margin: 0px;
  width: 12px;
  height: 12px;
  background-color: #FFF;
}
.carousel-theme-default .carousel-control {
  z-index: 12;
}
.carousel-theme-default .carousel-control.left {
  background-image: none;
}
.carousel-theme-default .carousel-control.right {
  background-image: none;
}
.carousel-theme-default .carousel-control .glyphicon-chevron-left,
.carousel-theme-default .carousel-control .carousel-control .icon-prev {
  left: 20%;
}
.carousel-theme-default .carousel-control .glyphicon-chevron-right,
.carousel-theme-default .carousel-control .carousel-control .icon-next {
  right: 20%;
}

.slide-title{
  font-size:1em;
  @media only screen and (min-width: 768px) {
    font-size: 1.6em;
  }
}