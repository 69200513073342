.pager{
	margin:0px;
	font-size:1.2em;
	li{

	}
}

.single-post .post-nav{
	padding-top:15px;
	margin-top:15px;
	border-top:1px solid $iron;
}

.wp-post-image{
	padding-bottom:15px;
}