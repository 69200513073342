.back-top{
    display:block;
    background:$hfm-green;
    border:1px solid $hfm-dk-green;
    border-radius:5px;
    font-size:1.6em;
    position:fixed;
    z-index:100;
    bottom:0px;
    right:3.5%;
    padding:5px 25px 10px;
    transition: all 200ms ease-in-out;
    opacity: 0;
    visibility:hidden;
    color:$hfm-dk-green;
    &:hover{
        color:$white;
        background:$hfm-dk-green;
    }
}

.back-top.visible {
    opacity: 0.75;
    bottom:10px;
    visibility: visible;
}

.colophon{

    border-top:15px solid $hfm-red;
    background-size:cover;
    padding-top:30px;
    padding-bottom:30px;
    .colo{
        // padding-top:20px;
        // padding-bottom:35px;
    }
}

.copyright{
    border-top:15px solid $hfm-red;
    background:$hfm-green;
    color:$white;
    padding-top:25px;
    padding-bottom:25px;
    font-size:1em;
    text-align:center;
    @media only screen and (min-width: 992px) {
        text-align:left;
    }
    a{
        color:$hfm-dk-green;
        display:inline-block;
        &:hover,&:focus{
            color:$hfm-green;
            text-decoration: underline;
        }
    }
}

.footer-cards{
    text-align:center;
    img{
        max-width:340px;
        @media only screen and (min-width: 768px) {

        }
        @media only screen and (min-width: 992px) {
            max-width: 100%;
        }
        @media only screen and (min-width: 1200px) {

        }
    }
}
.footer-assoc{
    text-align:center;
    margin-bottom:25px;
    img{
        max-width:280px;
        @media only screen and (min-width: 768px) {

        }
        @media only screen and (min-width: 992px) {
            max-width: 100%;
            margin-bottom:0px;
        }
        @media only screen and (min-width: 1200px) {

        }
    }
}

@media only screen and (min-width: 768px) {

}
@media only screen and (min-width: 992px) {

}
@media only screen and (min-width: 1200px) {

}